export enum ActivityLogSubjects {
  Claim = 'Claim',
  User = 'User',
  App = 'App',
  Message = 'Message',
  File = 'File',
  Admin = 'Admin',
  Organization = 'Organization',
  CrispEvent = 'Crisp Event',
}

export enum ActivityLogEventType {
  PageView = 'Page View',
  ItemClicked = 'Item Clicked',
  DocumentViewed = 'Document Viewed',
  View = 'View',
  Mutation = 'Mutation',
  Query = 'Query',
  AuthTokenNew = 'Auth Token New',
  AuthTokenRefresh = 'Auth Token Refresh',
  CrispEvent = 'Crisp Event',
  Login = 'Login',
  Logout = 'Logout',
  JoinCodeVerification = 'JoinCodeVerification',
}
