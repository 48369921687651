import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import { amplifyConfig } from '../config';
import type { User } from '../types/user';
import Register from '../pages/authentication/register';

Auth.configure({
  userPoolId: amplifyConfig.aws_user_pools_id,
  userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,
  region: amplifyConfig.aws_cognito_region,
});

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface RegisterUser {
  id: string;
  invitationCode: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  password: string;
  currentTerms: string;
}

export interface AuthContextValue extends State {
  platform: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: ActionType.LOGIN;
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: ActionType.LOGOUT;
};

type Action = InitializeAction | LoginAction | LogoutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log('sdfsdfsdfdsdf');
        // console.log(user);
        // console.log(user.signInUserSession.accessToken.jwtToken);

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        // Send token information to nestjs

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: formatUser(user),
          },
        });
      } catch (error) {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName) {
      console.error(
        `Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`,
      );
      return;
    }

    console.log(user);
    console.log(user.signInUserSession.accessToken.jwtToken);

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: formatUser(user),
      },
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: ActionType.LOGOUT,
    });
  };

  const register = async (registerUser: RegisterUser): Promise<void> => {
    await Auth.signUp({
      username: registerUser.id,
      password: registerUser.password,
      attributes: {
        'custom:appUserId': registerUser.id,
        'custom:invitationCode': registerUser.invitationCode,
        'custom:fullName': `${registerUser.firstName} ${registerUser.middleName} ${registerUser.lastName}`,
        'custom:currentTermsVersion': registerUser.currentTerms,
        email: registerUser.email,
        given_name: registerUser.firstName,
        family_name: registerUser.lastName,
        middle_name: registerUser.middleName || '',
      },
      clientMetadata: {
        appId: registerUser.id,
      },
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string,
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  };

  const formatUser = (user: any): User => ({
    _id: user.username,
    firstName: user.attributes.given_name,
    middleName: user.attributes.middle_name,
    lastName: user.attributes.family_name,
    emails: [{ emailAddress: user.attributes.email }],
    orgs: [{ org: { _id: '' } }],
  });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
